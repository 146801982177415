<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex>
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h3 class="headline mb-0">{{ visit.task.name }}</h3>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              <v-layout wrap>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>اسم المهمة</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ visit.task.name }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>نوع المهمة</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>زيارة</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>تكرار المهمة</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong
                    >{{
                      visit.task.reapetType
                        ? visit.task.reapetType == "d"
                          ? "يومي"
                          : visit.task.reapetType == "w"
                          ? "إسبوعي"
                          : visit.task.reapetType == "m"
                          ? "شهري"
                          : visit.task.reapetType == "y"
                          ? "سنوي"
                          : "-"
                        : "لا يوجد"
                    }}
                  </strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>عدد مرات التكرار</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{
                    visit.task.reapets ? visit.task.reapets : "لا يوجد"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6 v-if="visit.external">
                  <b>توع الجهة الخارجية</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6 v-if="visit.external">
                  <strong>{{
                    visit.external.cementPackage == 1
                      ? "مصنع"
                      : visit.external.cementPackage == 2
                      ? "مشروع"
                      : visit.external.cementPackage == 3
                      ? "مقاول"
                      : visit.external.cementPackage == 4
                      ? "مبسط"
                      : "-"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6 v-if="visit.external">
                  <b>اسم الجهة الخارجية</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6 v-if="visit.external">
                  <strong>{{ visit.external.name }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>مصدر المعلومات</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ visit.infoSource }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>حالة المهمة</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{
                    visit.task.statusId == 4
                      ? "جديدة"
                      : visit.task.statusId == 3
                      ? "منجزة"
                      : visit.task.statusId == 5
                      ? "بانتظار المراجعة"
                      : visit.task.statusId == 2
                      ? "قيد التقدم"
                      : "-"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>المستخدم المنشئ</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ userCreator }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>المستخدم الموكلة إليه</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ userResponsible }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>تاريخ البداية</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ createdDate(visit.task.startDate) }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>تاريخ النهاية</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ createdDate(visit.task.endDate) }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>الملاحظات</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ visit.notes ? visit.notes : "-" }}</strong>
                </v-flex>
                <v-flex xs12 md12 v-if="visit.productNeedVisits.length > 0">
                  <v-divider></v-divider>
                  <b class="mb-3">المنتجات</b>
                  <v-divider></v-divider>
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center"><b>اسم المنتج</b></th>
                          <th class="text-center"><b>السعر</b></th>
                          <th class="text-center"><b>الاحتياج اليومي</b></th>
                          <th class="text-center"><b>الاحتياج الشهري</b></th>
                          <th class="text-center"><b>الشركة</b></th>
                          <th class="text-center"><b>الناقل</b></th>
                        </tr>
                      </thead>
                      <tbody v-if="visit.productNeedVisits.length > 0">
                        <tr
                          v-for="(item, index) in visit.productNeedVisits"
                          :key="index"
                        >
                          <td class="text-center">
                            {{ item.prodName ? item.prodName : "-" }}
                          </td>
                          <td class="text-center">
                            {{ item.price ? item.price : "-" }}
                          </td>
                          <td class="text-center">
                            {{ item.dneed ? item.dneed : "-" }}
                          </td>
                          <td class="text-center">
                            {{ item.mneed ? item.mneed : "-" }}
                          </td>
                          <td class="text-center">
                            {{ item.comp.name ? item.comp.name : "-" }}
                          </td>
                          <td class="text-center">
                            {{ item.transporter ? item.transporter.name : "-" }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td
                            colspan="7"
                            class="text-center"
                            style="color:gray;font-size:12px;"
                          >
                            لا توجد بيانات لعرضها
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="$router.go(-1)"
                style="background:gray;color:white;"
                dark
              >
                <b>رجوع</b>
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      chatting: false,
      transporterName: "",
      userCreator: "",
      userResponsible: "",
      visit: {
        Name: "",
        TypeId: "",
        ResponsibleId: "",
        CreatorId: "",
        StatusId: "",
        Notes: "",
        StartDate: "",
        EndDate: "",
        ReapetType: "",
        Reapets: ""
      },
      users: []
    };
  },
  methods: {
    endChating() {
      this.chatting = false;
    },
    startChating() {
      this.chatting = true;
    },
    getUsersName() {
      this.users = [];
      this.ApiService.get("auth/listusers")
        .then(res => {
          this.users = res.data;
          this.users.forEach(el => {
            if (el.user.id == this.visit.task.creatorId) {
              this.userCreator = el.user.fullName;
            }
            if (el.user.id == this.visit.task.responsibleId) {
              this.userResponsible = el.user.fullName;
            }
          });
        })
        .catch(() => {});
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    },
    getTransporter(id) {
      this.ApiService.get("Transporters/getalltransporters")
        .then(res => {
          var allTransporters = res.data.responseData;
          allTransporters.forEach(el => {
            if (el.idtransporter == id) {
              this.transporterName = el.name;
            }
          });
        })
        .catch(() => {});
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.visit = this.$route.params.visit_details
      ? this.$route.params.visit_details
      : this.$router.go(-1);
    this.getUsersName();
    this.getTransporter(this.visit.transporterId);
  }
};
</script>
<style scoped>
b,
strong {
  font-size: 14px;
}
</style>
